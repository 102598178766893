import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import dayJs from 'dayjs';

@Directive({
  selector: '[appResetDateField]',
  standalone: true,
})
export class ResetDateFieldDirective {
  @Input() calendarShown: boolean;

  @HostListener('blur')
  onBlur(): void {
    const { control }: any = this.control;
    const date: string =
      control.value && control.value?.replace(/_/g, '')?.length === 11
        ? control.value?.replace(/_/g, '').slice(0, 10)
        : control.value?.replace(/_/g, '');

    if (!this.calendarShown && (date?.length < 10 || !dayJs(date).isValid())) {
      control.patchValue(date?.length < 10 ? '' : date);
    }
  }

  constructor(private control: NgControl) {}
}
